import type { FetchError } from 'ofetch';
import type { Ref } from 'vue';

/**
 * Takes a fetch error and display it the Nuxt error page
 * @param error - FetchError object
 */
export function useCmsError(error: Ref<FetchError> | undefined): void {
  if (!error?.value?.statusCode) return;

  const { isPreview } = useGlobalStore();
  if (isPreview) return;

  const errorClass: `${number}xx` = `${Math.floor(
    error.value.statusCode / 100
  )}xx`;
  const { t } = useClientI18n();

  if (errorClass === '4xx') {
    showError({
      statusCode: 404,
      message: t('error__page_not_found'),
    });
  }

  if (errorClass === '5xx') {
    showError({
      statusCode: 500,
      message: t('error__server_issue'),
    });
  }

  const { beforeEach } = useRouter();
  beforeEach(() => {
    clearError();
  });
}
